.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  color: #fff;
  display: flex;
  border-radius: 50px;
  font-size: 30px;
  z-index: 10;
  align-items: center;
  justify-content: center;
}

.float svg {
  width: 49%;
  height: 49%;
  fill: #fff;
}

.linkWhatsapp {
  right: 20px;
  background-color: #25d366;
  box-shadow: 0px 0px 13px #25d366 !important;
}

.linkTelegram {
  left: 20px;
  background-color: #0088cc;
  box-shadow: 0px 0px 13px #0088cc !important;
}
