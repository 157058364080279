.nosotrosColumn {
  background: #fff;
  border-radius: 50px;
  margin-top: 30px;
  width: min-content;
  padding: 35px;
  position: relative;
  z-index: 1;
  height: -webkit-fill-available;
  min-height: 270px;
  margin-top: 110px;
}
.nosotrosColumn h3 {
  color: var(--secondarycolor);
  margin-bottom: 10px;
  line-height: 110%;
  font-size: 2.5rem;
}
.nosotrosColumn p {
  color: #414141b2;
  font-weight: 600;
}
.imgNosotros {
  margin: -170px -30px 0px -30px;
  width: 280px;
}
.nosotrosColumn .blobCol {
  z-index: 0;
}
.boxCV {
  background: var(--maincolor);
  width: 100%;
  border-radius: 50px;
  padding: 40px 50px;
  margin-top: 80px;
}
#nosotros .titleServices {
  margin-bottom: 0;
}
#nosotros .button {
  background-color: var(--secondarycolor);
  margin-top: 10px;
}
.boxNosotros {
  width: 90%;
  margin-top: 20px;
}
@media (max-width: 850px) {
  .imgNosotros {
    width: 260px;
  }
  .nosotrosColumn h3 {
    font-size: 2.1rem;
  }
  .nosotrosColumn {
    min-height: 240px;
    height: fit-content;
  }
}
