div#cases {
  background: #0b091e;
}
.cardCasesDiv {
  gap: 50px 30px;
  margin-top: 40px;
}
.cardCase {
  width: 23%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 620px;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: flex-end;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}
.cardCase::before {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40%;
  width: 100%;
  background: linear-gradient(0deg, #6058cc, #ffffff00);
  content: "";
  z-index: 0;
}
.cardCase .button {
  position: relative;
  z-index: 1;
}
/* Media */
@media (max-width: 850px) {
  .cardCase {
    min-height: 350px;
    width: 47%;
  }
  .cardCase .button {
    padding: 13px 28px;
  }
  .cardCasesDiv {
    gap: 30px 20px;
  }
}
