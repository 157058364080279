.stepsDiv {
  display: flex;
  margin-top: 60px;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}
.stepsDiv .line {
  position: absolute;
  left: 0;
  width: 90%;
  height: 2px;
  background-color: #0005;
}
.stepsDiv .stepPoint {
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  margin-top: -10px;
  width: fit-content;
  cursor: default;
  align-items: center;
}
.stepsDiv .stepPoint h3 {
  position: absolute;
  top: 30px;
  font-size: 15px;
  color: #fff !important;
  background: #7f7cfd;
  margin-bottom: 0;
  padding: 5px;
  border-radius: 8px;
  width: fit-content;
  min-width: 100px;
}
.stepsDiv .stepPoint span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--maincolor);
  position: relative;
}
.stepsDiv .stepPoint span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid var(--maincolor);
  z-index: -1;
  border-radius: 50%;
  scale: 1.5;
}

@media (max-width: 850px) {
  .stepsDiv .stepPoint h3 {
    font-size: 13px;
  }
}
