/* Hero */
.hero {
  background: linear-gradient(10.13deg, #7f7cfd33 -8.44%, #ededff42 100.04%);
  height: 100vh;
  min-height: 770px;
}
.hero h1 {
  color: var(--darkcolortext);
}
.heroDev {
  background: #0b091e;
  min-height: 770px;
  height: auto;
  padding: 200px 0 100px;
}
.titleBox h4 {
  margin-top: 20px;
  width: 65%;
  font-weight: 400;
}
.heroDev #contacto {
  margin: 40px 0 70px;
}
.heroDev .cardTitle {
  margin-top: 70px;
}
.heroDev .cardTitleh2 {
  width: 70%;
}

#plans {
  background: #0b091e;
  padding-bottom: 70px;
}

.heroDev h1 {
  color: #f2f1ff;
  margin-bottom: 20px;
}

.hero p {
  color: #fff;
}
.titleBox {
  position: relative;
  z-index: 2;
}
.titleBox .button {
  width: fit-content;
  margin-top: 20px;
}
.heroLogo {
  width: 260px;
  height: auto;
  position: relative;
  z-index: 1;
  transform-origin: center !important;
}
.blob {
  position: absolute;
  width: 648px;
  height: auto;
  z-index: 0;
  margin-right: 100px;
}
.blobLogo {
  position: absolute;
}
/* cards */
.cardPricesDiv {
  gap: 40px;
  justify-content: space-between !important;
  align-self: normal;
  text-align: center;
  margin-top: 80px;
}

.cardPrice {
  padding: 20px 30px 120px;
  width: 29%;
  border: 3px solid #fff;
  position: relative;
  border-radius: 60px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardPrice .button {
  position: absolute;
  bottom: 30px;
}

.cardPrice:nth-child(even) {
  position: relative;
  top: -30px;
  box-shadow: 0px 0px 60px 40px #7851f538;
}

.cardPrice .blobBox {
  top: -270px;
  left: -130px;
  position: absolute;
  width: 480px;
  z-index: 0;
  rotate: 380deg;
}
.cardPrice h3 {
  position: relative;
  color: #fff;
  margin-bottom: 70px;
}
.cardPrice h4 {
  position: relative;
  color: #fff;
  font-size: 42px;
  margin-bottom: 0;
}
.requestTitle {
  line-height: 100%;
  font-size: 30px !important;
}
.cardPrice p {
  margin-top: 20px;
  font-size: 14px;
  color: #fff;
}

.enterpriceCard .blobBox {
  top: -218px;
  left: -60px;
  rotate: 390deg;
}
.cardPrice .iconBox {
  position: relative;
  top: 0;
  left: inherit;
  width: 50px;
  margin-bottom: 10px;
  z-index: 1;
}
.cardPrice span {
  color: #fff5;
  font-size: 13px;
}

.cardPrice h5 {
  position: relative;
  z-index: 1;
  color: #fff;
  background: rgb(120, 81, 245);
  margin: 0 auto 10px;
  box-shadow: 0px 0px 20px 10px #7851f538;
  padding: 8px 12px;
}

.cardPrice ul {
  margin: 20px 0 0;
  color: #fff;
  font-size: 14px;
  padding: 0;
}
.cardPrice li {
  margin: 0;
  margin-bottom: 5px;
  list-style: inside;
}

.cardPrice li::marker {
  color: #8bc34a;
}

@media (max-width: 991px) {
  .hero {
    min-height: 1000px;
  }
  .titleBox {
    margin-bottom: 40px;
  }
  .heroLogo {
    margin: 70px 50px 0;
  }
  .blob {
    margin-right: 0;
    margin-top: 70px;
  }
}

@media (max-width: 850px) {
  .heroLogo {
    width: 140px;
    margin: 100px 50px 0;
  }
  .blob {
    width: 400px;
  }
  .heroDev {
    padding: 120px 0 100px;
  }
  .cardPricesDiv {
    margin-top: 30px;
  }
  .cardPrice {
    width: 100%;
    margin: 0 20px;
    max-width: 400px;
  }
  .cardPrice .blobBox {
    top: -205px;
    left: -68px;
    width: 420px;
  }
  .cardPrice h3 {
    margin-bottom: 70px;
  }
  .cardPrice:nth-child(even) {
    top: 0;
  }
  .heroDev .cardTitleh2 {
    width: 100%;
  }
  .enterpriceCard .blobBox {
    top: -168px;
  }
  .titleBox h4 {
    width: 100%;
  }
  .hero {
    min-height: 910px;
  }
}
/* /Hero */
