#servicios {
  transition: all 0.6s;
  position: relative;
}
#servicios::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(7deg, #0b091e26 30%, #0b091e 100.04%);
  z-index: 0;
  opacity: 1;
  transition: all 0.6s;
}
.carru-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  padding: 0px 0 120px;
  overflow: visible;
  cursor: grab;
}
.serviciosDev {
  background: #0b091e !important;
}
.serviciosDev::before {
  content: none !important;
}
#servicios h3.titleServices {
  width: 90%;
}
.box {
  background-color: #fff;
  border-radius: 50px;
  padding: 20px 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  will-change: transform;
}
.boxP {
  border-radius: 50px;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 450px;
}

.box .blobBox {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transition: all 0.8s;
  transform: translate(-80px, -150px) scale(0.9);
  width: 400px;
  transform-origin: top left;
}
.boxP .button {
  position: absolute;
  z-index: -1;
  bottom: 0;
  transition: all 0.5s;
  transition-delay: 0.5s;
  transform: translateY(-70px);
}
.iconBox {
  width: 70px;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
  transition: all 0.5s;
  transform-origin: top left;
}

.titleCard {
  font-size: clamp(1.6rem, 10vw, 2.4rem);
  font-weight: 600;
  line-height: 100%;
}

.adds {
  margin-top: 15px;
}

.addsButtons {
  display: flex;
  flex-direction: row;
}

.addsButtons a {
  margin-right: 10px;
  font-size: 0.8rem;
  background-color: var(--maincolor);
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
}

.adds h4 {
  font-size: 0.8rem;
}

.pCard,
.adds {
  position: relative;
  opacity: 0;
  transition: all 0.5s;
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  margin: 0;
}
.boxP .tech-swiper {
  margin-top: 0;
}
.boxP.swiper-slide-active .pCard {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.4s;
  max-height: 190px;
  margin-bottom: 15px;
}
.boxP.swiper-slide-active .adds {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.4s;
  max-height: 100px;
}

.boxP.swiper-slide-active {
  width: 50% !important;
  max-width: 550px;
}

.boxP.swiper-slide-active .blobBox {
  transform: translate(-29px, -154px) scale(0.8) rotate(20deg);
}
.boxP.swiper-slide-active .button {
  transform: translateY(80px);
}
.boxP.swiper-slide-active .iconBox {
  transform: scale(1.3);
}

.pCard {
  font-size: clamp(0.6rem, 4vw, 0.8rem);
  font-weight: 400;
  font-weight: 400;
  line-height: 1.5;
  color: var(--darkcolortext);
  margin: 0 0 1rem;
}

@media (max-width: 991px) {
  .boxP {
    height: 440px;
  }

  .boxP.swiper-slide-active .blobBox {
    transform: translate(-29px, -204px) scale(0.93) rotate(20deg);
  }
}

@media (max-width: 849px) {
  .boxP {
    height: 480px;
  }
}

@media (max-width: 768px) {
  .boxP.swiper-slide-active {
    width: 100% !important;
  }
  .boxP {
    height: 560px;
  }
  .box {
    padding: 20px 20px 50px;
  }
  .pCard {
    font-size: clamp(0.6rem, 3.5vw, 0.8rem);
  }
  .boxP.swiper-slide-active .button {
    transform: translateY(70px);
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .boxP.swiper-slide-active {
    width: 70% !important;
  }
}
