/* Header */
.header {
  padding: 30px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}

.logo {
  width: 190px;
  height: auto;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.menu a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  width: 100%;
  font-size: 1rem;
  margin: 10px 10px;
  width: auto;
}
.hrefBlack a {
  color: var(--darkcolortext);
}
/* Lang Switch */
.switchLang {
  position: relative;
  min-width: 60px;
  background: #dfddff;
  cursor: pointer;
  border-radius: 10px;
  border: none !important;
}

.switchLang > div {
  width: 100%;
  display: none;
  position: absolute;
  bottom: 10px;
  left: 0;
  transform: translateY(100%);
  flex-direction: column;
  background: #efefef;
  padding-top: 15px;
  border-radius: 0 0 10px 10px;
  z-index: -1;
  overflow: hidden;
}

.switchActive {
  display: flex !important;
}
.switchLang button {
  width: 100%;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  background: transparent;
  color: var(--black);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  border: none !important;
}
.switchLang > div button:hover {
  background-color: var(--secondarycolor);
  color: #fff;
}
.switchLang img {
  width: 25px !important;
  height: auto;
  margin-top: 2px;
}

@media (max-width: 850px) {
  .header {
    padding: 15px 0;
  }
  .logo {
    width: 120px;
  }
  .menu {
    gap: 0;
  }
  .switchLang {
    margin-left: 20px;
  }
}

/* /Header */
