#workus {
  background: #0b091e;
}
#workus h3 {
  color: #fff;
}
.workus {
  background: #0b091e;
}
.carruWork {
  width: 100%;
  padding-bottom: 40px;
}
.boxWorkDiv {
  background: #fff;
  padding: 50px 40px;
  border-radius: 50px;
  height: auto;
  align-self: center;
}
.boxWorkDiv h3 {
  font-size: 24px;
}
.boxWorkDiv p {
  font-size: 13px;
}
.workus .navSlider {
  width: 95%;
  height: 100px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1310px;
}
.workus .blobCol {
  width: 30%;
  z-index: 0;
  min-width: 200px;
}
.workus .swiper-button-next,
.workus .swiper-button-prev {
  width: 50px !important;
  height: 40px !important;
  display: flex;
  background: #7f7cfd;
  color: #fff !important;
  border-radius: 10px;
  box-shadow: 0px 20px 30px rgb(43 40 90 / 15%);
  position: relative;
}
.workus .swiper-button-next,
.workus .swiper-rtl .swiper-button-prev {
  right: initial !important;
}
.workus .swiper-button-prev,
.workus .swiper-rtl .swiper-button-next {
  left: initial !important;
}
.workus .swiper-button-next:after,
.workus .swiper-rtl .swiper-button-prev:after,
.workus .swiper-button-prev:after,
.workus .swiper-rtl .swiper-button-next:after {
  font-size: 100% !important;
}

@media (max-width: 850px) {
  #workus {
    padding-top: 0;
  }
  .workus .navSlider {
    width: 100%;
  }
  .workus .swiper {
    overflow: visible;
  }
}
