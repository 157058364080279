.heroSec {
  background: #0b091e;
  padding-bottom: 0 !important;
  padding-top: 200px;
}
.form-container {
  background: #fff;
  border-radius: 50px;
  margin-top: 20px;
  padding: 80px;
}
.form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 20px;
}
.blacksec {
  background: #0b091e;
}
.form-container p {
  color: #0007;
}

input:not([type="submit"]) {
  width: 100%;
  border-radius: 50px;
  background-color: #4141411a;
  padding: 15px 20px 15px 90px;
  outline: none;
  border: none;
  color: #414141;
  transition: all 0.5s;
  caret-color: var(--maincolor);
  font-size: clamp(0.9rem, 4vw, 1rem);
}
input::placeholder {
  color: #414141;
  opacity: 0.6;
}
input:focus {
  box-shadow: inset 0px 0px 0px 2px var(--maincolor);
}
label {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
label svg {
  position: absolute;
  left: 25px;
}
.form .button {
  width: fit-content;
  padding: 15px 120px;
  align-self: center;
}
.isSubmit {
  font-size: 1.1rem;
  text-align: center;
  width: fit-content;
  align-self: center;
  padding: 20px;
  color: var(--maincolor);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.isSubmit svg {
  width: 40px;
  height: auto;
  margin-bottom: 10px;
}
.isSubmit path {
  stroke: var(--secondarycolor);
}
.error:empty {
  display: none;
}
.error {
  background: #f44336;
  text-align: center;
  align-self: center;
  width: fit-content;
  color: #fff;
  border-radius: 30px;
  padding: 3px 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
/* Media */

@media (max-width: 850px) {
  .form-container {
    padding: 70px 20px;
    width: 100%;
  }
  .form {
    width: 90%;
  }
  input:not([type="submit"]) {
    padding: 10px 20px 10px 60px;
    font-size: 0.9rem;
  }
  input::placeholder {
    font-size: 0.9rem;
  }
  .form .button {
    width: 100%;
    padding: 15px 60px;
  }
  label svg {
    left: 18px;
    width: 20px;
  }
  .isSubmit {
    font-size: 0.9rem;
  }
  .heroSec {
    padding-top: 130px;
  }
  .heroSec .titleBox {
    margin-bottom: 0;
  }
  .heroSec h1 span {
    display: block;
  }
}
