.escalaDiv {
  margin: 4rem 0;
}
.blobCol {
  position: absolute;
  width: 202px;
  height: auto;
  left: -40px;
}
.icon {
  margin-right: 20px;
  position: relative;
  will-change: transform;
}
.escalaColumn .icon {
  width: 20%;
}

.escalaColumn > div {
  width: 80%;
}
.escalaColumn {
  max-width: min(40%, 400px);
}

@media (max-width: 991px) {
  .escalaDiv {
    margin-top: 40px;
  }
  .blobCol {
    width: 150px;
    left: -30px;
  }
  .icon {
    max-width: 60px;
  }
  .escalaColumn {
    max-width: min(100%, 350px);
  }
  .buttonPortfolio {
    align-items: start !important;
    margin-top: 20px;
  }
}
