.colFooter {
  width: 100%;
}
.formNews {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}
#footer h5 {
  padding: 0;
  color: var(--maincolor);
  margin-right: auto;
}
#footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer ul a {
  color: #fff;
  font-size: 0.8rem;
}
#footer input:not([type="submit"]) {
  background-color: #303030;
  padding: 15px 50px 15px 20px;
  color: #979797;
  min-width: 250px;
  font-size: 0.9rem;
}
#footer input:not([type="submit"])::placeholder {
  color: #979797;
}
.buttonNews {
  position: absolute;
  right: 15px;
  background-color: transparent;
  border: none;
  margin-bottom: 2px;
}
.subFooter {
  width: 100%;
  margin-top: 40px;
}
.copy {
  color: #fff;
  font-size: 0.8rem;
  margin-top: 20px;
  margin: 0;
}
.hablemos h4 {
  margin: 0 0 -10px 0;
  color: #fff;
  opacity: 0.5;
  font-size: 1.2rem;
}
.hablemos a {
  margin: 0;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
}
.colFooterChild img {
  width: 150px;
  height: auto;
}
.isSubmitNews {
  position: absolute;
  right: 15px;
  background-color: transparent;
  border: none;
  margin-bottom: 2px;
}
.isSubmitNews path {
  stroke: #fff;
}
.privacy {
  color: #fff;
  font-size: 13px;
}
.privCopy {
  display: flex;
  flex-direction: column;
}
@media (max-width: 850px) {
  .colFooterChild {
    align-items: center !important;
    align-content: center;
  }
  .colFooter {
    justify-content: center !important;
  }
  #footer h5 {
    margin: 30px auto 20px;
  }
  #footer ul {
    text-align: center;
  }
  .subFooter {
    flex-direction: column-reverse !important;
    align-content: center;
    text-align: center;
  }
  .copy {
    margin-top: 40px;
    align-self: center;
  }
  .privCopy {
    width: 100%;
  }
}
