@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  scroll-behavior: smooth;
  scroll-margin-top: 60px;
}
/* Scroll */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

html,
body,
#root,
.App {
  position: relative;
}
html {
  font-family: "Poppins", sans-serif;
  line-height: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 100% 16px;
}

@media (max-width: 850px) {
  html {
    font-size: 17px;
  }
}
@media (min-width: 850px) and (max-width: 1200px) {
  html {
    font-size: 15px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
*:focus {
  outline: none !important;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
}
.App {
  overflow-x: hidden;
}

:root {
  --maincolor: #7f7cfd;
  --secondarycolor: #6058cc;
  --graycolor: #dbd8e3;
  --darkcolor: #414141;
  --darkcolortext: #2b285a;
}

/* Titles and Paragraph */
.black {
  color: var(--darkcolor);
}
.white {
  color: #fff;
}
.textCenter {
  text-align: center;
}

h1 {
  font-size: clamp(3.2rem, 7vw, 6rem);
  font-weight: 700;
  line-height: 110%;
  color: #fff;
  margin: 0;
}
h2 {
  font-size: clamp(2.5rem, 7vw, 3.3rem);
  font-weight: 700;
  line-height: 100%;
  color: var(--darkcolortext);
  margin: 0 0 1rem;
}
h3 {
  font-size: clamp(1.7rem, 7vw, 2.1rem);
  font-weight: 700;
  line-height: 120%;
  color: #fff;
  margin: 0 0 1rem;
}
p {
  font-size: clamp(0.9rem, 4vw, 1rem);
  font-weight: 400;
  line-height: 1.5;
  color: var(--darkcolortext);
  margin: 0 0 1rem;
}
p span {
  font-weight: 700;
}
.colP {
  font-size: clamp(0.7rem, 7vw, 0.8rem);
  margin: 0;
}
.titleResalt {
  color: var(--secondarycolor) !important;
}
.gradSpan {
  background: -webkit-linear-gradient(70deg, #6058cc 45.49%, #5da8dc 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h5 {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 100%;
  color: var(--darkcolortext);
  margin: 0 0 1.3rem 0;
  padding: 7px 30px;
  width: fit-content;
  border-radius: 8px;
  letter-spacing: 2px;
}

h5.primary {
  background: var(--maincolor);
  color: #fff;
}

h5.secondary {
  background: var(--secondarycolor);
  color: #fff;
}
h5.tertiary {
  background: var(--darkcolor);
  color: #fff;
}

@media (max-width: 850px) {
  h1 {
    font-size: max(9vw, 3.1rem);
  }
  h2 {
    font-size: max(8vw, 2rem);
  }
  h3 {
    font-size: clamp(1.6rem, 8vw, 1.8rem);
  }
  h5 {
    font-size: 0.6rem;
    padding: 5px 17px;
  }
}

/* /Titles and Paragraph */

/* Relative */

.relative {
  position: relative;
}

/* /Relative */

/* Container */
.container-lg {
  width: stretch;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container-lg .container-lg {
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 768px) {
  .container-lg {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container-lg {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container-lg {
    width: 1170px;
  }
}

@media (min-width: 1300px) {
  .container-lg {
    width: 1280px;
  }
}
/* /Container */

/* Sections */
.section {
  padding: 5rem 0;
}
.section .section {
  padding: 0;
}
.bckColor {
  background: var(--maincolor);
}
.bckGray {
  background: #414141d6;
}
.bckDark {
  background: var(--darkcolor);
}
.bckGrad {
  background: linear-gradient(0deg, #3b397c 0%, #0b091e 50%);
}
.noPadTop {
  padding-top: 0;
}
.noPadBot {
  padding-bottom: 0 !important;
}
/* /Sections */
/* Shadow */
.shadow {
  box-shadow: 0px 20px 30px rgb(43 40 90 / 15%);
}

/* /Shadow */

/* Boton */
.button {
  font-size: 1rem;
  padding: 15px 60px;
  border-radius: 40px;
  background: #7f7cfd;
  color: #fff;
  cursor: pointer;
  border: none;
}
.button.secondary {
  background: var(--secondarycolor);
}
@media (max-width: 850px) {
  .button {
    font-size: 0.9rem;
    padding: 13px 60px;
  }
}
/* /Boton */

/* Pages Simples */

.simplePage {
  background: linear-gradient(10.13deg, #7f7cfd33 -8.44%, #ededff42 100.04%);
  padding: 240px 150px 200px;
  display: flex;
}

.simplePage h1 {
  margin-bottom: 40px;
}

.simplePage h2 {
  margin-top: 100px;
  margin-bottom: 30px;
}

/* Media */

@media (max-width: 850px) {
  .simplePage {
    padding: 150px 20px 100px;
  }
}
