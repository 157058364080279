.tech-swiper {
  display: flex;
  justify-content: center;
}

.tech-swiper img {
  width: 50px;
  height: auto;
  max-height: 45px;
  object-fit: contain;
}

.tech-swiper .swiper-wrapper {
  display: flex;
  align-items: center;
  transition-timing-function: linear;
}
.tech-swiper {
  width: 100%;
  margin-top: 20px;
  position: relative;
  mask-image: linear-gradient(
    90deg,
    transparent 0%,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 1) 90%,
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent 0%,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 1) 90%,
    transparent 100%
  );
}

/* Media */
@media (max-width: 850px) {
  .tech-swiper img {
    width: 35px;
    height: auto;
    max-height: 45px;
  }
}
